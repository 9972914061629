import React, { useState, useEffect } from 'react'
import { CDBContainer, CDBDataTable } from 'cdbreact'
import AdminBase from '../AdminBase'
import axios from 'axios'
import useToken from '../../Authentication/Token'


export default function ManageUsers() {
    const [userList, setUserList] = useState([])

    function fetchUserList() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
            url: "/api/admin/users"
        })
        .then(response => {
            setUserList(response.data) 
        })
        .catch(error => {
                if ( error.response.status === 401 ) {
                    alert("Your session has expired. Please log in again.")
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    window.location.href = '/auth/login'
                }
                else if ( error.response.status === 422 ) {
                    alert("You are not authorized to view this page. Please log in.")
                    window.location.href = '/'
                }
                console.error("Error fetching users", error)
            })

        }
    useEffect(() => {
        fetchUserList()
    }, [])
    const data = () => {
        return {
            columns: [
                {
                    label: "User ID",
                    field: "user_id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                },
                {
                    label: "Role",
                    field: "role",
                    sort: "asc",
                },
                {
                    label: "Email Confirmed",
                    field: "confirmed",
                    sort: "asc",
                },
                {
                    label : "Admin Approved",
                    field: "admin_approved",
                    sort: "asc",
                },
                {
                    label: "View",
                    field: "view",
                    sort: "asc",
                }
            ],
            rows: userList.map(user => ({
                user_id: user.user_id,
                name: user.name,
                email: user.email,
                role: user.role,
                confirmed: user.confirmed ? "Yes" : "No",
                admin_approved: user.admin_approved ? "Yes" : "No",
                view: <a className='button' href={`/admin_new/users/${user.user_id}`}>View</a>
            })
            
            )
        }
    }
    return (
        <AdminBase>
          <h1>Manage Users</h1>
            <CDBContainer>
                    <CDBDataTable
                        striped
                        bordered
                        hover
                        responsive
                        data={data()}
                    />
            </CDBContainer>
        </AdminBase>
    )
}
