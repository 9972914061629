import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import AdminBase from '../AdminBase'
import axios from 'axios'
import { useParams } from 'react-router-dom'

export default function EditJobFolder() {
    const { folderId } = useParams()
    const [folder, setFolder] = useState({})

    function fetchJobFolder() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            },
            url: `/api/admin/job_folders/${folderId}`,
        })
        .then(response => {
            setFolder(response.data)
        })
        .catch(error => {
            console.error("Error fetching job folder", error)
        })
    }
    useEffect(() => {
        fetchJobFolder()
    }, [])
    
    return(
        <AdminBase>
            <Form>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Folder Path</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        placeholder="Folder Path"
                        value={folder.folder_path}
                        disabled
                    />
                </InputGroup>
            
            </Form>
        </AdminBase>
    )
}