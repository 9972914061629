import React, { useEffect } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import "../App.css"
import useToken from "../Authentication/Token"
import Footer from "../Layout/Footer"

const AdminNavBar = () => {
    // check if user is admin
    const { token, isAdmin, verifyToken } = useToken()
    useEffect(() => {
        if (token){
            verifyToken()
        }
    }, [token, verifyToken]
)
    return (
        <Navbar bg="light" sticky="top" collapseOnSelect>
            <Container>
                <Navbar.Brand href="/admin_new">TLDR Admin Console</Navbar.Brand>
                    <Nav.Item> <Nav.Link href='/admin_new/users'>Users</Nav.Link></Nav.Item> &nbsp;
                    <Nav.Item> <Nav.Link href='/admin_new/roles'>Roles</Nav.Link></Nav.Item> &nbsp;
                    <Nav.Item> <Nav.Link href='/admin_new/modules'>Modules</Nav.Link></Nav.Item> &nbsp;
                    <Nav.Item> <Nav.Link href='/admin_new/jobFolders'>Job Folders</Nav.Link></Nav.Item> &nbsp;
                    <Nav.Item> <Nav.Link href='/admin_new/statistics'>Statistics</Nav.Link></Nav.Item>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                    <Nav>
                        <NavDropdown title="Welcome Admin" id="collasible-nav-dropdown" className="ml-auto">
                            <NavDropdown.Item href="/">Back to TLDR</NavDropdown.Item>
                            <NavDropdown.Item href="/auth/logout">Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        
    )
}

export default function AdminBase({ children }) {
    return (
        <div>
            <AdminNavBar />
            <Container className="content">
                {children}
            </Container>
            <Footer />
        </div>
    );
}

