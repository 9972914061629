import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import Form from "react-bootstrap/Form"
import AdminBase from "../AdminBase"
import axios from "axios"
import { useParams } from "react-router-dom"
import useToken from "../../Authentication/Token"

export default function EditRole(){
    // check if user is admin
    const { token, verifyToken, removeToken } = useToken()
    useEffect(() => {
        if (token){
            verifyToken()
        }
    })
    const { roleId } = useParams()
    const [role, setRole] = useState({})

    function fetchRole(){
        axios({
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
            },
            url: `/api/admin/roles/${roleId}`,
        })
        .then(response => {
            setRole(response.data)
        })
        .catch(error => {
            console.error("Error fetching role", error)
        })
    }
    const updateRole = (e) => {
        e.preventDefault()

        axios({
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
            },
            url: `/api/admin/roles/${roleId}`,
            data: role
        })
        .then(response => {
            console.log(response)
            if (response.status === 200) {
                alert("Role updated successfully")
                window.location.href = "/admin_new/roles"
            }
            else if (response.status === 401) {
                alert("Your session has expired. Please log in again.")
                removeToken()
                window.location.href = "/auth/login"
            }
        })
        .catch(error => {
            console.error("Error updating role", error)
        })
    }
    useEffect(() => {
        fetchRole()
    }, [roleId])

    return (
        <AdminBase>
            <Form onSubmit={updateRole}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="name">Name</InputGroup.Text>
                    <Form.Control
                        type="text"
                        value={role.role}
                        onChange={(e) => setRole({ ...role, role: e.target.value })}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="description">Description</InputGroup.Text>
                    <Form.Control
                        type="text"
                        value={role.description}
                        onChange={(e) => 
                            setRole({ ...role, description: e.target.value })}
                    />
                </InputGroup>
                <Button variant="primary" type="submit">Save</Button> &nbsp;
                <Button variant="primary" type="submit" href="/admin_new/roles">Cancel</Button>
            </Form>
        </AdminBase>
    )

}