import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import './index.css';
import reportWebVitals from './reportWebVitals'

// Import pages
import HomePage from './MainPages/HomePage'

// Import module pages
import ListModules from './Modules/ListModules'
import ModulePage from './Modules/Module'

// Import job pages
import JobDetail from './Job/JobDetail'
import JobList from './Job/JobList'

// Import authentication pages
import LoginForm from './Authentication/Login'
import Logout from './Authentication/Logout'
import RegisterForm from './Authentication/Register'
import EmailConfirmationPage from './Authentication/EmailConfirmation'
import ForgotPassword from './Authentication/ForgotPassword'
import ResetPassword from './Authentication/ResetPassword'

// Import admin pages
import AdminConsole from './Admin/AdminConsole'
//// Manage Users
import ManageUsers from './Admin/Users/ManageUsers'
import EditUser from './Admin/Users/EditUser'
//// Manage Roles
import ManageRoles from './Admin/Roles/ManageRoles'
import EditRole from './Admin/Roles/EditRole'
//// Manage Modules
import ManageModules from './Admin/Modules/ManageModules'
import EditModule from './Admin/Modules/EditModule'
//// Manage Job Folders
import ManageJobFolders from './Admin/Folders/ManageJobFolders'
import EditJobFolder from './Admin/Folders/EditJobFolder'

//// Statistics
import ViewStatistics from './Admin/Statistics'

// Create a router
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/auth/login',
    element: <LoginForm />,
  },
  {
    path : '/auth/logout',
    element: <Logout />,
  },
  {
    path: '/auth/register',
    element: <RegisterForm />,
  },
  {
    path: '/auth/confirm',
    element: <EmailConfirmationPage />,
  },
  {
    path: '/auth/forgotPassword',
    element: <ForgotPassword />,
  },
  {
    path: '/auth/resetPassword/:token',
    element: <ResetPassword />,
  },
  {
    path: '/start',
    element: <ListModules />,
  },
  {
    path: '/start/:moduleName',
    element: <ModulePage />,
  },
  {
    path: '/jobs/:jobId/:jobPath?',
    element: <JobDetail />,
  },
  {
    path: '/jobs',
    element: <JobList />,
  },
  {
    path: '/admin_new',
    element: <AdminConsole />,
  },
  {
    path: '/admin_new/users',
    element: <ManageUsers />,
  },
  {
    path: '/admin_new/users/:userId',
    element: <EditUser />,
  },
  {
    path: '/admin_new/roles',
    element: <ManageRoles />,
  },
  {
    path: '/admin_new/roles/:roleId',
    element: <EditRole />,
  },
  {
    path: '/admin_new/modules',
    element: <ManageModules />,
  },
  {
    path: '/admin_new/modules/:moduleId',
    element: <EditModule />,
  },
  {
    path: '/admin_new/statistics',
    element: <ViewStatistics />,
  },
  {
    path : '/admin_new/jobFolders',
    element: <ManageJobFolders />,
  },
  {
    path : '/admin_new/jobFolders/:folderId',
    element: <EditJobFolder />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <RouterProvider router={router} />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
