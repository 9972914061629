import React, { useState, useRef, useEffect } from "react";
import {
    Button, 
    Container,  
    Card, 
    Row,
    Col,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
    import InputGroup from 'react-bootstrap/InputGroup'
    import ReCAPTCHA from "react-google-recaptcha"

import Base from "../Layout/Base"
import axios from "axios"

const RegisterForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [siteKey, setSiteKey] = useState('')
    const recaptchaRef = useRef()


    // fetch the site key from the server
    useEffect(() => {
        const fetchSiteKey = async () => {
            try {
                const response = await fetch('/api/auth/sitekey')
                const data = await response.json()
                setSiteKey(data.siteKey)
            }
            catch (error) {
                console.error('Error fetching sitekey:', error)
            }
        }
        fetchSiteKey()
    }, []);

    const handleRegister = (e) => {
        e.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue()
        if (!recaptchaValue) {
            alert('Please verify that you are not a robot')
            return
        }
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        const data = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName
        };
        console.log(data);
        // Send a POST request to the server
        axios.post('/api/auth/register', data)
        .then((response) => {
            console.log(response.data);
            alert(response.data.msg)
            window.location.href = '/auth/login';
        })
        .catch((error) => {
            console.log(error);
            alert(error.response.data.msg)
              
        }
        );
    }
return (
    <Base>
        <Container style={{width:'80%'}}>
            <Card>
                <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Register</h3>
                    <Button variant="link" href="/auth/login">Already have an account?</Button>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleRegister}>
                        <Row>
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="firstName"><i className="bi bi-person-fill"></i></InputGroup.Text>    
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            required
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3">
                                <InputGroup.Text id="lastName"><i className="bi bi-person-fill"></i></InputGroup.Text>

                                    <Form.Control
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        required
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </InputGroup>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3">
                                <InputGroup.Text id="email"><i className="bi bi-envelope-at"></i></InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="password"><i className="bi bi-key"></i></InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            
                            </Col>
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="password2"><i className="bi bi-key"></i></InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    required
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                        </InputGroup>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {siteKey &&  <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} /> }
                            </Col>
                        </Row>
                        < br/>
                       
                        
                    
                        <Button variant="primary" type="submit" block>
                            Register
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    </Base>

)}
export default RegisterForm;