import React, { useState, useEffect } from "react"
import { Navbar, Nav, Container, Modal, Button } from "react-bootstrap"
import NavDropDown from "react-bootstrap/NavDropdown"
import InputGroup from "react-bootstrap/InputGroup"
import Form from 'react-bootstrap/Form'
import axios from "axios"
import "../App.css"
import useToken from "../Authentication/Token"



const TopNavBar = () => {
  const { token, isAdmin, user, apiToken, verifyToken } = useToken()
  useEffect(() => {
    if (token){
      verifyToken()
    }
    })
  const [showModal, setShowModal] = useState(false)

  const toggleModal = (e) => {
    e.preventDefault()
    setShowModal(true)
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiToken)
    alert("Copied")
  }

  return (
    <div>
    <Navbar bg="light" sticky="top" collapseOnSelect>
      <Container>
        <Navbar.Brand href="/">TLDR</Navbar.Brand>
        {token && (
          <>
           <Nav.Item> <Nav.Link href='/start'>Start</Nav.Link></Nav.Item> &nbsp;
            <Nav.Item> <Nav.Link href='/jobs'>Jobs</Nav.Link></Nav.Item>
          </>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" >
          <Nav>
          <NavDropDown title={`Welcome ${user ? user.toUpperCase() : 'Guest'}`} id="collasible-nav-dropdown" className="ml-auto">
              { user ? (
                <>
                { isAdmin ? (
                  <>
                  <NavDropDown.Item href='/admin_new'>Admin Console</NavDropDown.Item>
                  </>
                ) : (null)
              }   <NavDropDown.Item onClick={toggleModal}>API Token</NavDropDown.Item>        
                  <NavDropDown.Item href="/auth/logout">Logout</NavDropDown.Item>
                </>
              ) : (
                <>
                 <NavDropDown.Item href="/auth/login">Login</NavDropDown.Item>
                  <NavDropDown.Item href="/auth/register">Register</NavDropDown.Item>
                </>
              )
              }
            </NavDropDown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>API Token</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="justify-content-between">
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="API Token"
            aria-label="apiToken"
            value={apiToken}
            readOnly
          />
        <InputGroup.Text onClick={copyToClipboard} id="apiToken"><i class="bi bi-clipboard"></i></InputGroup.Text>
      </InputGroup>
      </div>
      </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowModal(false)}>
        Close
      </Button>
    </Modal.Footer>
    </Modal>
    </div>
  );
};

export default TopNavBar;