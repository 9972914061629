import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import axios from 'axios'
import Base from '../Layout/Base'

export default function ForgotPassword() {

    const [email, setEmail] = useState('')

    const handleResetPassword = (e) => {
        e.preventDefault()
        axios({
            method: 'POST',
            url: '/api/auth/reset_password',
            data: {
                email: email
            }
        })
        .then(response => {
            console.log(response)
            alert(response.data.msg)
        })
        .catch(error => {
            console.log(error)
            alert(error.response.data.msg)
        })
    }
    return (
        <Base>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Header>
                                <h3>Forgot Password</h3>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleResetPassword}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="email"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    < br/>
                                    <Button variant="primary" type="submit">
                                        Reset Password
                                    </Button>
                                </Form>
                      
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </Base>
    )
}