import React, { useEffect, useState } from 'react'
import '../App.css'

export default function RangeSlider({min, max, step, prefix, onUpdateRange}) {
    const [minValue, setMinValue] = useState(min)
    const [maxValue, setMaxValue] = useState(max)

    const [range, setRange] = useState(`${min} - ${max}`)

    useEffect(() => {
        setRange(`${prefix}${minValue} - ${prefix}${maxValue}`)
        onUpdateRange(`${prefix}${minValue} - ${prefix}${maxValue}`)
    }, [minValue, maxValue, onUpdateRange])

    const handleMinChange = (e) => {
        const newMin = parseInt(e.target.value)
        if (newMin <= maxValue) {
            setMinValue(newMin)
            // update range
            setRange(`${prefix}${newMin} - ${prefix}${maxValue}`)
        }
    }
    const handleMaxchange = (e) => {
        const newMax = parseInt(e.target.value)
        if (newMax >= minValue) {
            setMaxValue(newMax)
            // update range
            setRange(`${minValue} - ${newMax}`)
        }
    }
    const calculateSliderProgress = () => {
        const range = max - min
        const valueRange = maxValue - minValue
        const progress = valueRange / range * 100
        return progress
    }
    const calculateSliderProgressLeft = () => {
        const range = max - min
        const valueLeft = (minValue - min) / range * 100
        return valueLeft
    }
    return (
        <div className="range-slider-container">
        <div className='range-text-input'>
            <div className="field">
                <span>Min</span>
                <input 
                    type="number"
                    className="input-min"
                    value={minValue}
                    onChange={handleMinChange}
                    />
            </div>
            <div className='field'>
                <span>Max</span>
                <input 
                    type="number"
                    className="input-max"
                    value={maxValue}
                    onChange={handleMaxchange}
                    />
            </div>
            
        </div>
         <div className='input-container'>
         <div className='rangeslider'>
            <div className='slider-progress' 
                    style={{ width: `${calculateSliderProgress()}%`, left: `${calculateSliderProgressLeft()}%` }}>
            </div>
         </div>
         <div className="range-input">
             <input
                 type="range"
                 className="range-min"
                 id="min-value"
                 min={min}
                 max={max}
                 step={step}
                 value={minValue}
                 onChange={handleMinChange}
             />
             <input
                 type="range"
                 className="range-max"
                 id="max-value"
                 min={min}
                 max={max}
                 step={step}
                 value={maxValue}
                 onChange={handleMaxchange}
             />

         </div>
     </div>
     </div>
    )
}