import React, { useEffect, useState } from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import NavDropDown from "react-bootstrap/NavDropdown"
import AdminBase from "./AdminBase"
import "../App.css"


export default function AdminConsole(){
    return (
        <AdminBase>
            <h3>Admin Console</h3>
            <p>You can update user permissions, roles</p>
        </AdminBase>
    )
}

