import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
        Col,
        Row,
        Button,
        Container,
        Form
    } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import Base from '../Layout/Base'
import useToken from '../Authentication/Token'
import axios from 'axios'
import RangeSlider from '../Components/RangeSlider'


// TODO:
// 1. check smiles files if there are 2 columns and if the second column is empty add a new column
// 2. check if the smiles file is a valid smiles file

export default function ModulePage() {
    const { removeToken } = useToken()
    const { moduleName } = useParams()
    const [module, setModule] = useState({})
    const formData = new FormData()

    async function fetchModuleParams() {
        axios({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            url: "/api/modules/" + moduleName
        })
        .then(response => {
            setModule(response.data.module);
        })
        .catch(error => {
            if ( error.response.status === 401 ) {
                alert("Your session has expired. Please log in again.")
                removeToken()
                window.location.href = '/auth/login'
            }
            else if ( error.response.status === 422 ) {
                alert("You are not authorized to view this page. Please log in.")
                window.location.href = '/'
            }
            alert(error.response.data.msg)
        })
    }
    useEffect(() => {
        fetchModuleParams()
    }
    , [])

   
    function handleSubmit(e) {
        console.log(e)
        e.preventDefault(); 
        // Update values in formData
        for (let param of module.params) {
            if (!param.required && !e.target[param.file_name].value) {
                continue;
            }
            if (param.type === 'file_upload') {
                // Check file extension
                const fileExt = e.target[param.file_name].files[0].name.split('.').pop()
                if (!param.allowed_ext.includes(fileExt)) {
                    alert(`Invalid file extension for ${param.file_name}. Allowed extensions are ${param.allowed_ext.join(',')}`);
                    return;
                }
                // Check file size, max 500MB 
                if (e.target[param.file_name].files[0].size > 500 * 1024 * 1024) {
                    alert(`File size too large for ${param.file_name}. Max file size is 500MB`);
                    return;
                } else {
                    let filename = ""
                    // check if param file_name already has an extension
                    if (param.file_name.includes('.')) {
                        filename = param.file_name
                    } else {
                        filename = param.file_name
                    }
                    formData.append(filename, e.target[param.file_name].files[0]);
                }
            } else if (param.type === 'range_slider') {
                console.log("range_slider")
            } else {
                formData.append(param.file_name, e.target[param.file_name].value);
            }
        }
        // add memo to formData
        formData.append('memo', e.target.memo.value);
       
        // Send formData to backend
        axios({
            method: 'POST',
            url: `/api/modules/${moduleName}/submit`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: formData
        })
        .then(response => {
            const job_id = response.data.job_id
            console.log('Job submitted with job_id:', job_id);
            // Redirect to job detail page
            window.location.href = `/jobs/${job_id}`; 
        })
        .catch(error => {
            console.log(error);
        })
    }

    const SampleData = () => {
        const sampleData = module.sample_data || []
        return (
            <div>
                { sampleData.length > 0 &&
                    <div>
                        <h5><i className='bi bi-info-square'></i>&nbsp;Sample Data</h5>
                        <ul>
                            <li>
                                {sampleData.map((file, index) => (
                                    <li>
                                        <a href={`/api/modules/${moduleName}/sample_data/${file.name}`} download>
                                            {file.name}
                                        </a>
                                    </li>
                                ))}
                            </li>
                        </ul>
                    </div>
                    }    
            </div>        
        )
    }

    const ModuleParams = () => {
        const params = module.params || []
           // Handle radiobutton select
        const [selectedValue, setSelectedValue] = useState({})
        const handleRadioChange = (paramIndex, optionValue) => {
            setSelectedValue(prevState => ({
                ...prevState,
                [paramIndex]: optionValue
            }))
        }
    const handleTextInputChange = (paramIndex, textValue) => {
        setSelectedValue(prevState => ({
            ...prevState,
            [paramIndex]: textValue
        }))
    }

    const validateSmilesFile = (file) => {
        const reader = new FileReader();
        reader.onload = function(e) {
            const lines = e.target.result.split('\n');
            for (let i = 1; i < lines.length; i++) {
                const line = lines[i].split('\t');
                if (line.length === 1) {
                    // Add empty column
                    lines[i-1] = line[0] + '\t' + i;
                }
            }
            const newFile = lines.join('\n');
            const blob = new Blob([newFile], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');

        }
        reader.readAsText(file);
    }


    const handleSliderChange = (paramIndex, sliderValue) => {
        setSelectedValue(prevState => {
            // Check if slider value actually changed
            if (prevState[paramIndex] !== sliderValue) {
                const newState = {
                    ...prevState,
                    [paramIndex]: sliderValue
                };
                return newState
            }
            return prevState
        });
        if (formData.has(paramIndex)) {
            formData.delete(paramIndex);
        }
        formData.append(paramIndex, sliderValue);
    };
    
        return (
            params.map(param => (
                     param.type === 'file_upload' ? (
    
                        <InputGroup className="mb-4">
                            <InputGroup.Text>{param.file_name} ({param.allowed_ext.join(',')})</InputGroup.Text>
                            <Form.Control 
                                type="file" 
                                name={param.file_name}
                                placeholder={param.display_text}
                                { ...param.required && { required: true }}
                             />
                             
                        </InputGroup>
                    ) : 
                    ( param.type === 'radiobutton' ? (
                    <InputGroup className="mb-3">
                          <InputGroup.Text>{param.file_name}</InputGroup.Text>&nbsp;
                      { param.values.map((value) => (
                            <Form.Check
                                inline
                                type="radio"
                                label={value}
                                name={param.file_name}
                                id={value}
                                checked={selectedValue[param.file_name] === value}
                                onChange={() => handleRadioChange(param.file_name, value)}
                                { ...param.required && { required: true }}
                            />
                        ))}
                    </InputGroup>
                    ) :
                     (
                        param.type === 'drop_down' ? (
                        <InputGroup className="mb-3">
                            <InputGroup.Text>{param.file_name}</InputGroup.Text>
                            <Form.Select aria-label="Default select example"
                                name={param.file_name}
                                 { ...param.required && { required: true }}>
                                <option>Select</option>
                                {param.values.map((value) => (
                                    <option key={value}>{value}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    ) : ( param.type === 'text' ? (
                            param.value_type === 'number' ? (
                            <InputGroup className="mb-3">
                                <InputGroup.Text>{param.file_name}</InputGroup.Text>
                                <Form.Control   type="number" 
                                                step={param.step}
                                                defaultValue={param.default}
                                                placeholder={param.default}
                                                name={param.file_name}
                                                onChange={(e) => handleTextInputChange(param.file_name, e.target.value)}
                                                { ...param.required && { required: true }}

                                                />
                            </InputGroup>
                            ): (
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{param.file_name}</InputGroup.Text>
                                    <Form.Control   type="text"
                                                    placeholder={param.display_text} 
                                                    name={param.file_name}/>
                                </InputGroup>
                            )
                    )  : ( param.type === 'range_slider' ? (
                        <InputGroup className="mb-3">
                            <InputGroup.Text>{param.file_name}</InputGroup.Text>
                            <RangeSlider    min={param.value.min_value}
                                            max={param.value.max_value}
                                            step={param.value.step}
                                            prefix={param.value.prefix}
                                            name={param.file_name}
                                            onUpdateRange={(value) => handleSliderChange(param.file_name, value)}       
                            />
                        </InputGroup>
                    ) : (
                        <InputGroup className="mb-3">
                            <InputGroup.Text>{param.file_name}</InputGroup.Text>
                            <Form.Control   type="text" 
                                            placeholder={param.display_text} 
                                            name={param.file_name}/>
                        </InputGroup>
                    )
                )
            )
                )
            ))
            )
        )
    }
    return (

        <Base>
            <div className="container">
                <h3>{moduleName}</h3>
                <hr />
                <h5><i className='bi bi-info-square'></i>&nbsp;Description</h5>
                <p>{module.description}</p>
                <Row>
                   { module.sample_data &&
                    <Col sm={3}>
                        <SampleData />
                    </Col>
                    }
                    <Col>
                  
                        <h5>Inputs</h5>
                        <Form onSubmit={handleSubmit}>
                            <ModuleParams />
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Memo</InputGroup.Text>
                                <Form.Control   as="textarea" 
                                                name="memo"
                                                rows={3} 
                                                placeholder="Enter memo for your job" />
                            </InputGroup>
                            <Button variant="primary" type="submit">
                                Submit Job
                            </Button>
                        </Form>
                       
                    </Col>
                </Row>
            </div>
        </Base>
    );
}