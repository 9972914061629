import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import Base from '../Layout/Base'


const ResetPassword = () => {
    const { token } = useParams()
    console.log(token)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const setNewPassword = (e) =>{
        e.preventDefault()
        if(password !== confirmPassword){
            alert('Passwords do not match')
            return
        }
        const data = {
            token: token,
            password: password
        }
        axios({
            method: 'POST',
            url: '/api/auth/set_new_password',
            data: data
        })
        .then(response => {
            console.log(response)
            alert(response.data.msg)
        })
        .catch(error => {
            console.log(error)
            alert(error.response.data.msg)
        })
    }
    return(
        <Base>
            <Form onSubmit={setNewPassword}>
                <Form.Group>
                
                    <InputGroup>
                        <Form.Label>Enter new password</Form.Label>
                        <Form.Control 
                                    type="password" 
                                    placeholder="Enter new password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} />
                    </InputGroup>
                    <InputGroup>
                        <Form.Label>Confirm new password</Form.Label>
                        <Form.Control 
                                    type="password" 
                                    placeholder="Confirm new password" 
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)} />
                    </InputGroup>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Reset Password
                </Button>
            </Form>
        </Base>
    )

}
export default ResetPassword