import React, { useState, useEffect } from 'react'
import { CDBContainer, CDBDataTable } from 'cdbreact'
import AdminBase from '../AdminBase'
import axios from 'axios'
import { useToken } from '../../Authentication/Token'

export default function ManageJobFolders() {
    const [folderList, setFolderList] = useState([])

    function fetchFolderList() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            },
            url: "/api/admin/job_folders"
        })
        .then(response => {
            setFolderList(response.data) 
        })
        .catch(error => {
            console.error("Error fetching job folders", error)
        })
        }

    useEffect(() => {
        fetchFolderList()
    }, [])
    const data = () => {
        return {
            columns: [
                {
                    label: "Folder ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Path",
                    field: "path",
                    sort: "asc",
                },
                {
                    label: "Actions",
                    field: "actions",
                    sort: "asc",
                },
            ],
            rows: folderList.map(folder => ({
                id: folder.id,
                path: folder.path,
                actions: <a href={`/admin_new/jobFolders/${folder.id}`}>Edit</a>
            }))
        }
    }
    return (
        <AdminBase>
            <CDBContainer>
                <CDBDataTable
                    striped
                    bordered
                    small
                    data={data()}
                />
            </CDBContainer>
        </AdminBase>
    )
}