import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { Dropdown, Row, Col } from 'react-bootstrap'
import "chart.js/auto"
import AdminBase from './AdminBase'
import useToken from '../Authentication/Token'
import axios from 'axios'
import Nav from 'react-bootstrap/Nav'

export default function ViewStatistics() {
    const { token, isAdmin, user, verifyToken } = useToken()
    const [stats, setStats] = useState({})
    const [activeGraph, setActiveGraph] = useState('users')

    useEffect(() => {
        if (token) {
            verifyToken();
            fetchStats();
        }
    }, [token]); // Fetch stats whenever token changes

    function fetchStats() {
        axios({
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            },
            url: "/api/admin/statistics"
        })
        .then((res) => {
            setStats(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const UserBarGraph = ({ stats }) => {
        const labels = Object.keys(stats)
        console.log(labels)
        const activeUserCounts = labels.map(label => stats[label].activeUserCount)
        const userSignupCounts = labels.map(label => stats[label].userSignupCount)
        console.log(activeUserCounts)
        console.log(userSignupCounts)

        const barData = {
            labels: labels,
            datasets: [
                {
                    label: "Active Users",
                    data: activeUserCounts,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 1
                },
                {
                    label: "User Signups",
                    data: userSignupCounts,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1
                }
            ]
        };

        return (
            <div>
                <h5>User Statistics</h5>
                <Bar 
                    data={barData}
                    options={{
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'day(s)'
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Count'
                                }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
    const JobBarGraph = ({ stats }) => {
        const labels = Object.keys(stats)
        console.log(labels)
        const submittedJobCounts = labels.map(label => stats[label].submittedJobCount)
        const successJobCounts = labels.map(label => stats[label].successJobCount)

        const barData = {
            labels: labels,
            datasets: [
                {
                    label: "Submitted Jobs",
                    data: submittedJobCounts,
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 1
                },
                {
                    label: "Success Jobs",
                    data: successJobCounts,
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1
                }
            ]
        };

        return (
            <div>
                <h5>Jobs Statistics</h5>
                <Bar 
                    data={barData}
                    options={{
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'day(s)'
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Count'
                                }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
    const ModulesBarGraph = ({ stats }) => {
        console.log(stats)
        const [selectTime, setSelectTime] = useState('1')
        const handleDropdownChange = (eventKey) => {
            setSelectTime(eventKey)
        }
        const renderDropdown = (keyList) => {
            return (
                <Dropdown onSelect={handleDropdownChange}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        {selectTime}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {keyList.map((key) => (
                            <Dropdown.Item eventKey={key}>
                                 {key === 'all' ? 'All Time' : `${key} day(s)`}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                
            )
        }
        const renderModuleGraph = () => {
            const selectedData = stats[selectTime]
            const labels = Object.keys(selectedData.moduleJobsCount)
            const counts = Object.values(selectedData.moduleJobsCount)
            const barData ={
                labels : labels,
                datasets : [
                    {
                        label: "Module Jobs",
                        data: counts,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 1
                    }
                ]
            }
            return (
                <div>
                <Bar
                    data={barData}
                    options={{
                        scales: {
                            xAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Module'
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Count'
                                }
                            }]
                        }
                    }}
                />
                </div>

            )
        }
        return (
            <div>
                <h5>Module Statistics</h5>
                <Row>
                    <Col  md={{ span: 2, offset: 11 }}>
                        {renderDropdown(Object.keys(stats))}
                    </Col>
                </Row>
             
                {renderModuleGraph()}
            </div>
        )

    }

    return (
        <AdminBase>
            <h5>Statistics</h5>
            <Nav fill variant='tabs' defaultActiveKey='/admin_new/statistics'>
                <Nav.Item>
                    <Nav.Link onClick={() => setActiveGraph('users')} active={activeGraph === 'users'}>User Statistics</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => setActiveGraph('jobs')} active={activeGraph === 'jobs'}>Job Statistics</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={() => setActiveGraph('modules')} active={activeGraph === 'modules'}>Module Statistics</Nav.Link>
                </Nav.Item>
            </Nav>
            {activeGraph === 'users' ? <UserBarGraph stats={stats} /> : activeGraph === 'jobs' ? <JobBarGraph stats={stats}  /> : <ModulesBarGraph stats={stats} />}
        </AdminBase>
    );
}
