import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import Badge from 'react-bootstrap/Badge';
import { CDBContainer, CDBDataTable } from 'cdbreact';
import AdminBase from '../AdminBase';
import useToken from '../../Authentication/Token';

export default function ManageModules() {
    // check if user is admin
    const { token, verifyToken, removeToken } = useToken();

    useEffect(() => {
        if (token) {
            verifyToken();
        }
    }, []);

    const [moduleList, setModuleList] = useState([]);

    function fetchModuleList() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token
            },
            url: "/api/admin/modules"
        })
        .then(response => {
            setModuleList(response.data);
        })
        .catch(error => {
            if (error.response.status === 401) {
                alert("Your session has expired. Please log in again.");
                removeToken();
            } else if (error.response.status === 422) {
                alert("You are not authorized to view this page. Please log in.");
                window.location.href = '/';
            }
            console.error("Error fetching modules", error);
        });
    }

    useEffect(() => {
        fetchModuleList();
    }, []);

    const data = () => {
        return {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc"
                },
                {
                    label: "Module Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Description",
                    field: "description",
                    width: 100,
                    sort: "asc"
                },
                {
                    label: "Status",
                    field: "status",
                    sort: "asc"
                },
                {
                    label: "View",
                    field: "view",
                    sort: "asc"
                }
            ],
            rows: moduleList.map(module => {
                return {
                    id: module.id,
                    name: module.name,
                    description: module.description,
                    status: (
                        module.status === 'production' ? (
                            <Badge bg="success">Production</Badge>
                        ) : (
                            module.status === 'development' ? (
                                <Badge bg="secondary">Development</Badge>
                            ) : (
                                <Badge bg="dark">Hidden</Badge>
                            )
                        )
                    ),
                    view: <Button href={`/admin_new/modules/${module.id}`} variant="light">View</Button>
                };
            })
        };
    };

    return (
        <AdminBase>
            <CDBContainer>
                <CDBDataTable
                    striped
                    bordered
                    hover
                    responsive
                    entries={50}
                    itemsPerPageSelect
                    small
                    data={data()}
                />
            </CDBContainer>
        </AdminBase>
    );
}
