import React from "react";

import Base from "../Layout/Base";


export default function HomePage() {
    var today = new Date();
    var date = today.toLocaleString("default", {month : "long"}) + " "+today.getDate()+', '+today.getFullYear();
    return (
        <Base>
            <div className="home">
                <h3>tldr</h3>
                <hr />
                <p>Welcome to <i>tldr</i> (Tldr's a Ligand Discovery Resource), a public access service for computational ligand discovery. tldr contains tools to help you find small molecules you can purchase and test for activity against your biological target.</p>
                <p><i>tldr</i> is provided by the Irwin and Shoichet Laboratories in the Department of Pharmaceutical Chemistry at the University of California, San Francisco (UCSF).</p>

                <p>The current version of tldr is unpublished. For now, to cite tldr, please reference Irwin, Shoichet, Mysinger et al., "Automated docking screens: a feasibility study.", J. Med. Chem. 2009, 52(18), pp 5712-5720.</p>
                <p>To use tldr, you must create an account and log in.</p>
                <p>Caveat Emptor: We do not guarantee the quality of any calculation for any purpose and take no responsibility for errors arising from the use of this system. tldr is provided in the hope that it will be useful, but you must use it at your own risk.</p>
                <p>Acknowledgements: The interface for tldr was originally created by Supritha Amudhu. Current developer of tldr is Khanh Tang. Past contributions from Jennifer Young, Chinzorig Dandarchuluun, Benjamin Wong, and Teague Sterling.</p>

                <p>tldr is a work in progress ({date}). Any faults are the responsibility of John Irwin chemistry4biology at gmail dot com.</p>

                <p>This work is supported by the National Institutes of Health (GM71896).</p>
            
            </div>
        </Base>
    );
    }