import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CDBContainer, CDBDataTable } from 'cdbreact'
import axios from 'axios'
import AdminBase from '../AdminBase'
import useToken from '../../Authentication/Token'

export default function ManageRoles(){
    // check if user is admin
    const { token, isAdmin, verifyToken, removeToken } = useToken() 
    useEffect(() => {
        if (token){
            verifyToken()
        }
        }
    )
    const [roleList, setRoleList] = useState([])
    function fetchRoleList(){
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token
            },
            url: "/api/admin/roles"
        })
        .then(response => {
            setRoleList(response.data)    
        })
        .catch(error => {
            if ( error.response.status === 401 ) {
                alert("Your session has expired. Please log in again.")
                removeToken()
            }
            else if ( error.response.status === 422 ) {
                alert("You are not authorized to view this page. Please log in.")
                window.location.href = '/'
            }
            console.error("Error fetching roles", error)
        })
    }
    useEffect(() => {
        fetchRoleList()
    }, [])
    const data = () => {
        return {
            columns : [
                {
                    label : "Role ID",
                    field : "role_id",
                    sort : "asc"
                },
                {
                    label : "Name",
                    field : "role",
                    sort : "asc"
                },
                {
                    label : "Description",
                    field : "description",
                    sort : "asc"
                },
                {
                    label : "View",
                    field : "View",
                    sort : "asc"
                }
            ],
            rows : roleList.map(role => {
                return {
                    role_id : role.role_id,
                    role : role.role,
                    description : role.description,
                    View : <a href={`/admin_new/roles/${role.role_id}`}><Button>View</Button></a>
                }
            })
        }
    }
    return (
        <AdminBase>
            <CDBContainer>
                <CDBDataTable
                    striped
                    bordered
                    hover
                    responsive
                    entries={5}
                    pages={5}
                    itemsPerPageSelect
                    small
                    data={data()}
                />
            </CDBContainer>
        </AdminBase>
    )
}

