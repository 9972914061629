import React from "react";
import "../App.css";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer">
            <p>&copy; {currentYear}  <a target="_blank"  rel="noopener noreferrer" href="http://irwinlab.compbio.ucsf.edu/">Irwin</a> and <a target="_blank" rel="noopener noreferrer" href="http://bkslab.org/">Shoichet</a> Labs and the UC Regents</p>
            <p><a  target="_blank" rel="noopener noreferrer"  href="https://www.nigms.nih.gov/">Supported by NIGMS via GM71896</a>. &nbsp;
            <a  target="_blank" rel="noopener noreferrer" href="https://forms.gle/LZV1FCmLWxUWznVi9">Contact Us</a> &nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://wiki.docking.org/index.php/Terms_And_Conditions">Terms of Use</a> &nbsp;
            <a target="_blank"  rel="noopener noreferrer"  href="https://wiki.docking.org/index.php/Privacy_policy">Privacy policy</a></p>
            <p className="text-muted small">This is not an official UCSF website. The opinions or statements expressed herein should not be taken as a position of or endorsement by the University of California, San Francisco.</p>
        </footer>
    );
};
export default Footer;
