import React from "react";
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";
import "../App.css";

import TopNavBar from "./TopNavBar";
import Footer from "./Footer";
// import SideBar from "./SideBar";



export default function Base({ children }) {
 
    return (
        <div>
            <Container fluid>
           
                {/* <SideBar /> */}
              
                        <TopNavBar />
                        <Container className="content">
                            {children}
                        </Container>
                        <Footer />
        
            </Container>
        
        </div>
    );
}
