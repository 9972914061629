import React, { useEffect, useState } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import AdminBase from '../AdminBase'
import axios from 'axios'
import { useParams } from 'react-router-dom'


export default function EditUser() {
    const { userId } = useParams()
    const [user, setUser] = useState({})
    const [roleList, setRoleList] = useState([])

    function fetchRoleList() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            },
            url: "/api/admin/roles",
        })
        .then(response => {
            let roles = []
            response.data.map(role => {
                roles.push({label: role.role, value: role.role_id})
            })
            setRoleList(roles)
            
        })
        .catch(error => {
            console.error("Error fetching role list", error)
        })
        }

    function fetchUser() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            },
            url: `/api/admin/users/${userId}`,
        })
        .then(response => {
            setUser(response.data)
            console.log(response.data)
        })
        .catch(error => {
            console.error("Error fetching user", error)
        })
    }
    const updateUser = (e) => {
        e.preventDefault()
        axios({
            method: "POST",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
            },
            url: `/api/admin/users/${userId}`,
            data: user
            })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    alert("User updated successfully")
                    window.location.href = "/admin_new/users"
                }
            })
            .catch(error => {
                console.error("Error updating user", error)
            })
    }
    useEffect(() => {
        fetchUser()
        fetchRoleList()
    }, [])
   
    return(
        <AdminBase>
            <Form onSubmit={updateUser}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="name">Name</InputGroup.Text>
                    <Form.Control
                        type="text"
                        aria-label="Name"
                        aria-describedby="Name"
                        value={user.name}
                        disabled
                    />
                </InputGroup>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                        <Form.Check
                                type="checkbox"
                                label="Admin Approved"
                                aria-label="adminApproved"
                                aria-describedby="adminApproved"
                                {...user.admin_approved && {checked: true}}
                                onChange={(e) => setUser({...user, admin_approved: e.target.checked})}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Account Confirmed"
                                aria-label="accountConfirmed"
                                aria-describedby="accountConfirmed"
                                {...user.confirmed && {checked: true}}
                                onChange={(e) => setUser({...user, confirmed: e.target.checked})}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Admin"
                                    aria-label="admin"
                                    aria-describedby="admin"
                                    {...user.admin && {checked: true}}
                                    onChange={(e) => setUser({...user, admin: e.target.checked})}
                                />
                            </InputGroup>
                    
                    </Col>
                </Row>

              
               
                <InputGroup className='mb-3'>
                    <InputGroup.Text id="role">Role</InputGroup.Text>
                    <Select
                        value={{label: user.role, value: user.role_id}}
                        options={roleList}
                        onChange={(e) => setUser({...user, role: e.label, role_id: e.value})}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="email">Email</InputGroup.Text>
                    <Form.Control
                        type="email"
                        aria-label="Email"
                        aria-describedby="email"
                        value={user.email}
                        disabled
                    />
                </InputGroup>
             
                <Button variant="primary" type="submit">Submit</Button> &nbsp;
                <Button variant="secondary" href="/admin_new/users">Cancel</Button>
            </Form>
        </AdminBase>
    )
}