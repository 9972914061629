import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import AdminBase from '../AdminBase'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import useToken from '../../Authentication/Token'

export default function EditModule() {
    // check if user is admin
    const { token, verifyToken, removeToken } = useToken()
    useEffect(() => {
        if (token) {
            verifyToken()
        }
    })
    const { moduleId} = useParams()
    const [module, setModule] = useState({})

    function fetchModule() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token,
            },
            url: `/api/admin/modules/${moduleId}`,
        })
        .then(response => {
            setModule(response.data)
        })
        .catch(error => {
            console.error("Error fetching module", error)
        })
    }
    const [moduleStatusList, setModuleStatusList] = useState([])
    function fetchModuleStatusList() {
        axios({
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token,
            },
            url: `/api/admin/module_status`
        })
        .then(response => {
            let statuses = []
            response.data.map(status => {
                statuses.push({ value: status.id, label: status.name })
            })
            setModuleStatusList(statuses)
        })
        .catch(error => {
            console.error("Error fetching module status list", error)
        })
    }
    const updateModule = (e) => {
        e.preventDefault()
        console.log(module)
        axios({
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
            },
            url: `/api/admin/modules/${moduleId}`,
            data: module
            })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    alert("Module updated successfully")
                    window.location.href = '/admin_new/modules'
                }
            })
            .catch(error => {
                console.error("Error updating module", error)
            })

    }

    useEffect(() => {
        fetchModule()
        fetchModuleStatusList()
    }, [])
    return (
        <AdminBase>
            <Form onSubmit={updateModule}>
                <Form.Group>
                    <Form.Label>Module Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={module.name}
                        disabled
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Module Description</Form.Label>
                    <Form.Control
                        type="text"
                        name="description"
                        value={module.description}
                        onChange={e => setModule({ ...module, description: e.target.value })}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Module Status</Form.Label>
                    <Select
                        value={{label : module.status, value: module.status}}
                        options={moduleStatusList}
                        onChange={e => setModule({ ...module, status: e.label })}
                    ></Select>
                </Form.Group>
                <br />
                <Button type="submit">Update Module</Button> &nbsp;
                <Button variant='danger' href="/admin_new/modules">Cancel</Button>
            </Form>
        </AdminBase>
    )
}