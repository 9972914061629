import React, { useRef, 
                useState, 
                useEffect } from 'react'
import {Button, 
        Container, 
        Card } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'
import Form from 'react-bootstrap/Form' 
import InputGroup from 'react-bootstrap/InputGroup'
import ReCAPTCHA from "react-google-recaptcha"
import Base from "../Layout/Base"
import useToken from './Token'


const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [siteKey, setSiteKey] = useState('')
  const recaptchaRef = useRef()

  const { saveToken } = useToken()

  // Fetch the site key from the server
  useEffect(() => {
    const fetchSiteKey = async () => {
      try {
        const response = await fetch('/api/auth/sitekey')
        const data = await response.json()
        setSiteKey(data.siteKey)
      } catch (error) {
        console.error('Error fetching sitekey:', error)
      }
    };

    fetchSiteKey()
  }, []);

  // Handle login form submission
  const handleLogin = (e) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (!recaptchaValue) {
      alert('Please verify that you are not a robot')
      return
    }
    const data = {
      email: email,
      password: password,
      rememberMe: rememberMe
    };
    // Send a POST request to the server
    axios.post('/api/auth/login', data)
      .then((response) => {
        // Save the token and user data in local storage
        saveToken(response.data.token, response.data.userData, response.data.isAdmin)
        if (response.next_url) {
          window.location.href = response.next_url
        } else {
          window.location.href = '/'
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.msg)
      })

  }

  return (
    <Base>
        <Container style={{width:'80%'}}>
            <Card>
                <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>Login</h3>
                                <Button variant="link" href="/auth/forgotPassword">Forgot password?</Button>
                          
                </Card.Header>
                <Card.Body >
                    <Form onSubmit={handleLogin}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="email"><i className="bi bi-envelope-at-fill"></i></InputGroup.Text>
                            <Form.Control
                                placeholder="Enter email"
                                aria-label="email"
                                aria-describedby="email"
                                type='email'
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="password"><i className="bi bi-lock-fill"></i></InputGroup.Text>
                            <Form.Control
                                placeholder="Enter password"
                                aria-label="password"
                                aria-describedby="password"
                                type='password'
                                value={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </InputGroup> 
                        <Row className="justify-content-end" style={{paddingLeft:"15px"}}>
                          <Col>
                            <InputGroup className="mb-3"> 
                              {siteKey &&  <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} /> }
                            </InputGroup>
                          </Col>
                          <Col xs="auto">
                            <InputGroup className="mb-3">
                              <Form.Check
                                  type="checkbox"
                                  id="rememberMe"
                                  label="Remember Me"
                                  checked={rememberMe}
                                  onChange={(e) => setRememberMe(e.target.checked)}
                              />
                        </InputGroup>
                          
                          </Col>
                        </Row>        
                            <Button variant="success" type="submit">Login</Button>
                        < hr />
                        <small>Don't have an account! <a href='/auth/register'>Sign Up Here</a></small>
                       
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    </Base>
  );
};

export default LoginForm;
