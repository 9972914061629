// EmailConfirmationPage.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'

const EmailConfirmationPage = () => {
  const location = useLocation()

  // Extract message from URL parameters
  const searchParams = new URLSearchParams(location.search)
  const message = searchParams.get('msg')

  // Store message in session storage
  useEffect(() => {
    sessionStorage.setItem('confirmationMessage', message)
  }, [message]);

  // Redirect to login page after displaying message
  useEffect(() => {
    const redirect = () => {
      window.location.href = '/auth/login'
    };

    const timeout = setTimeout(redirect, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      <h1>Email Confirmation</h1>
      {message && <p>{message}</p>}
      <p>Redirecting to login page...</p>

    </div>
  );
};

export default EmailConfirmationPage;
